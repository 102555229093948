@import './variables';

$themecolor: $gunmetal-blue;
$topbar: $gunmetal-blue;
$topbar-alt: $sidebar-background;
@import './steps';

@import './vendor/randock/adminpress-bundle/src/Resources/assets/scss/colors/blue';

.topbar ul.dropdown-user li .dw-user-box .u-text .btn:hover {
  background: $info;
}

#sticky-wrapper {
  border: 0px !important;
}

form p {
  margin-bottom: 30px;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cecece;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #cecece;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: #cecece;
}
@media (min-width: 579px) {
  .form-horizontal label {
    text-align: right;
  }
}

input[type="file"] {
  margin-bottom: 5px;
}

.table-md td, .table-md th {
  padding: .4rem
}

.sidebar-nav > ul > li > a i {
  height: 28px;
}

.checkbox-decorator{
  display: block !important;
}

.modal-dialog{
  max-width: 800px;
}

